<template>
  <hit-form-definition-builder-base-component
    :validations="validations"
    :value="value"
  >
    <template #default="{formData, validationState, changeField}">
      <hit-select
        :value="formData.reference"
        :options="entityOptions"
        name="reference"
        :label="t('hit-base.form.entity')"
        :validation-state="validationState.reference"
      />
      <tag-list
        :label="t('hit-base.common.tags')"
        :id-list="formData.tagList"
        :tag-entities-to-load="[formData.reference]"
        @change="(data) => changeField('tagList', data)"
      />
    </template>
  </hit-form-definition-builder-base-component>
</template>

<script>
import HitFormDefinitionBuilderBaseComponent from './HitFormDefinitionBuilderBaseComponent';
import {useI18n} from 'vue-i18n';
import TagList from 'hit-online-web-ui/src/components/hit/list/TagList.vue';
import {HitSelect} from '@hit/components';

export default {
  name: 'EntityComponentForm',
  components: {
    HitFormDefinitionBuilderBaseComponent,
    HitSelect,
    TagList,
  },
  props: {
    /**
     * Entity component of the builder to edit
     */
    value: {
      type: Object,
      required: true,
    },
    /**
     * Validations for the entity component
     */
    validations: {
      type: Object,
      required: true,
    },
  },

  /**
   * The save and cancel event is emitted inside the HitFormDefinitionBuilderBaseComponent
   * We do not catch it here and do not define the events in this component. Like this,
   * the event listeners added to this component propagates to the base builder
   */
  emits: [],
  setup() {
    const {t} = useI18n();
    return {t};
  },
  data() {
    return {
      entityOptions: [
        {
          id: 'staff',
          label: this.t('hit-base.common.staff-short'),
        },
        {
          id: 'equipment',
          label: this.t('hit-base.common.equipment', 1),
        },
        {
          id: 'country',
          label: this.t('hit-base.common.country'),
        },
        {
          id: 'address',
          label: this.t('hit-base.common.address', 1),
        },
        {
          id: 'project',
          label: this.t('hit-base.common.project', 1),
        },
        {
          id: 'project_part',
          label: this.t('hit-app.common.project-part', 1),
        },
        {
          id: 'resource',
          label: this.t('hit-base.common.resource', 1),
        },
      ],
    };
  },
};
</script>

<style scoped></style>
