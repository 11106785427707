<template>
  <hit-entity-browser
    :title="t('hit-base.common.browser-title-equipment')"
    route="equipment"
    :table-attributes="tableAttributes"
    auto-complete-id="equipment"
    :data-list-config="dataListConfig"
    :default-sort="{property: 'no', order: 'asc'}"
    :only-load-active-records="onlyLoadActiveRecords"
    :custom-filters="customFilters"
  />
</template>
<script>
import HitEntityBrowser from './HitEntityBrowser.vue';
import {
  HitContainerAttribute,
  ATTRIBUTE_TYPES,
} from '../../container/HitContainerAttribute';
import {useI18n} from 'vue-i18n';
import {HitLanguageUtils} from '../../../utils';

export default {
  name: 'HitEquipmentBrowser',
  components: {
    HitEntityBrowser,
  },
  props: {
    onlyLoadActiveRecords: {
      type: Boolean,
      required: false,
      default: false,
    },
    forInjection: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const {t} = useI18n();
    return {t};
  },
  data() {
    return {
      tableAttributes: {
        no: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
          tableProperties: {
            header: this.t('hit-app.common.number'),
            maxWidth: '1fr',
          },
        }),
        designation: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
          tableProperties: {
            header: this.t('hit-app.common.designation'),
            mainSearch: true,
            maxWidth: '2fr',
          },
        }),
        active: new HitContainerAttribute(ATTRIBUTE_TYPES.BOOLEAN, {
          tableProperties: {
            header: this.t('hit-app.common.active'),
            maxWidth: '0.5fr',
          },
        }),
        tags: new HitContainerAttribute(ATTRIBUTE_TYPES.TAGS_READ_ONLY, {
          tableProperties: {
            header: this.t('hit-base.common.tags'),
            maxWidth: '1fr',
          },
        }),
        resource: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
          column: `cost_resource_id(id, no, ${HitLanguageUtils.localizeString(
            'designation',
            'company'
          )}, base_price, unit_id)`,
          formItemName: 'resource',
        }),
      },
      dataListConfig: {
        zone1Field: 'no',
      },
    };
  },

  computed: {
    customFilters() {
      const filters = {};
      if (this.forInjection) {
        filters['resource'] = 'not.is.null';
      }
      return filters;
    },
  },
};
</script>
