<template>
  <hit-select
    v-if="!readOnly"
    :label-fields="['designation']"
    :options="tags"
    :value="idList"
    multiple
    @remove="(tagId) => $emit('remove', tagId)"
  />
  <div v-else class="flex flex-row gap-1 flex-wrap">
    <div
      v-for="item in designationListToDisplay"
      :key="item.designation"
      class="flex-row flex font-medium py-1 px-1 rounded h-5 items-center"
      :style="{'background-color': `${item.bg}`, color: `${item.font}`}"
    >
      <div class="px-1 text-xs font-normal leading-none max-w-full">
        {{ item.designation }}
      </div>
      <hit-icon
        v-if="removableReadOnly"
        icon="clear"
        clickable
        @click="$emit('remove', item.designation)"
      />
    </div>
  </div>
</template>

<script>
import {HitIcon, HitSelect} from '@hit/components';
import {DataService} from '@hit/base/src';
import {ColourUtils} from '@hit/components/src/utils/colour';

export default {
  name: 'TagList',
  components: {HitSelect, HitIcon},
  props: {
    idList: {
      type: Array,
      required: false,
      default: () => [],
    },
    designationList: {
      type: Array,
      required: false,
      default: () => [],
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * Can be used to pass the available tags instead of making one request per line to load the active tags
     * Like this, they are loaded once with the container data and can then be used for the display of the tags
     */
    container: {
      type: Object,
      required: false,
    },
    /**
     * Pass an array of entities with the different entities the tags are loaded
     * For example ['address', 'staff'] loads all tags where address_visible or
     * staff_visible is set to true
     */
    tagEntitiesToLoad: {
      type: Array,
      required: false,
      default: null,
    },

    /**
     * The read only implementation existed already but in some cases, we need to add a clear icon behind the tag
     */
    removableReadOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ['remove'],
  data() {
    return {
      tags: [],
    };
  },
  computed: {
    /**
     * We only display the tags that belong to the entities we want to load
     * If no entity types are passed, we display all the tags
     * project_part needs to fall back to project because they use the same column
     */
    tagLoadEntityFilter() {
      if (this.tagEntitiesToLoad !== null) {
        let orStatement = '';
        this.tagEntitiesToLoad.forEach((entity) => {
          const entityKey = entity === 'project_part' ? 'project' : entity;
          orStatement += `${entityKey}_visible.is.true,`;
        });
        return `(${orStatement.slice(0, -1)})`;
      }
      return null;
    },
    designationListToDisplay() {
      const designationList = [];
      if (this.idList.length > 0) {
        const availableTags = this.container ? this.container.tags : this.tags;
        this.idList.forEach((tagToDisplay) => {
          const tagObject = availableTags.find(
            (tag) => tag.id === tagToDisplay
          );
          if (tagObject) {
            designationList.push({
              designation: tagObject.designation,
              bg: tagObject.colour
                ? `#${tagObject.colour}`
                : 'var(--color-tag)',
              font: ColourUtils.getFontColorOnBg(
                tagObject?.colour ?? 'var(--color-tag)'
              ),
            });
          }
        });
        return designationList;
      } else if (this.designationList.length > 0) {
        this.designationList.forEach((item) => {
          designationList.push({
            designation: item,
            bg: 'var(--color-tag)',
            font: ColourUtils.getFontColorOnBg('var(--color-tag)'),
          });
        });
      }
      return designationList;
    },
  },
  mounted() {
    if (!this.container) {
      this.loadTags();
    }
  },
  methods: {
    loadTags() {
      let filter = {active: 'is.true'};
      if (this.tagLoadEntityFilter) {
        filter['or'] = this.tagLoadEntityFilter;
      }
      DataService.read('tag', {
        attributes: 'id,designation,colour',
        filters: filter,
      }).then((response) => (this.tags = response.data));
    },
  },
};
</script>
