import {DataService} from '../api';
import {HitUUIDUtils} from '@hit/components';
import {useConfigurationStore} from '../store';
import {$d} from '../plugins/i18n/i18n';

export class HitUtils {
  /**
   * Converts the given string in camel case to kebab-case
   */
  static camelToKebab(camel) {
    return camel.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
  }

  /**
   * Converts the given string in camel case to snake_case
   */
  static camelToSnake(camel) {
    return camel.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase();
  }

  /**
   * Converts the given kebab case to snake case (aB-Cd -> ab_cd)
   */
  static kebabToSnake(kebab) {
    return kebab.toLowerCase().replaceAll('-', '_');
  }
}

export class HitPdfUtils extends HitUtils {
  /**
   * Downloads the response sent by an API as a PDF
   */
  static downloadResponseAsPdf(response) {
    let fileType = response.headers['content-type'];
    let filename = response.headers['content-disposition'].slice(21);
    let arrayBuffer = response.data;
    const blob = new Blob([arrayBuffer], {type: fileType});
    const fakeUrl = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = fakeUrl;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(fakeUrl);
  }
}

export class HitTagUtils extends HitUtils {
  /**
   * Creates the name of the table where the tags are registered for this type of entities
   */
  static tagTable(entityType) {
    return `${this.camelToSnake(entityType)}_tag`;
  }

  /**
   * Creates the name of the column used as FK between the tag table and the entity
   */
  static entityFk(entityType) {
    return `${this.camelToSnake(entityType)}_id`;
  }

  /**
   * Links the tag to the entity by adding it to the tag table
   */
  static registerTag(entityType, entityId, tagId) {
    const data = {tag_id: tagId};
    data[this.entityFk(entityType)] = entityId;
    return DataService.upsert(this.tagTable(entityType), data);
  }

  /**
   * Removes the tag from the entity by deleting it from the tag table
   */
  static removeTag(entityType, entityId, tagId) {
    const data = {tag_id: `eq.${tagId}`};
    data[this.entityFk(entityType)] = `eq.${entityId}`;
    return DataService.delete(this.tagTable(entityType), data);
  }
}

export class HitDateUtils extends HitUtils {
  /**
   * Formats the date object to use it as postgrest query parameter
   */
  static toPostgrestParam(dateObject) {
    return dateObject.toISOString();
  }

  /**
   * Formats the date object to the following format YYYY-MM-DD
   */
  static toYYYYMMDD(dateObject) {
    const year = dateObject.getFullYear();
    const month = String(dateObject.getMonth() + 1).padStart(2, '0');
    const day = String(dateObject.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  /**
   * Formats the dateObject to only display year, month and day in the locale format
   */
  static toShortDate(dateObject) {
    return $d(dateObject, 'short');
  }

  /**
   * Formats the dateObject to display year, month, day and then hours and minutes
   * By setting the show seconds flag, we also display the second in the time output
   */
  static toLongDate(dateObject, showSeconds = false) {
    return showSeconds ? $d(dateObject, 'longSeconds') : $d(dateObject, 'long');
  }

  /**
   * Displays only the time part of the date without displaying the date
   */
  static toTime(dateObject, showSeconds = false) {
    return showSeconds
      ? this.toLongDate(dateObject, true).slice(-8)
      : this.toLongDate(dateObject).slice(-5);
  }

  /**
   * Returns the date object of the Monday of the same week
   */
  static getMondayOfWeek(dateObject) {
    const monday = new Date(dateObject);
    const day = dateObject.getDay();
    const distanceToMonday = (day + 6) % 7;
    monday.setDate(dateObject.getDate() - distanceToMonday);
    return monday;
  }

  /**
   * For some use-cases, we need to be at UTC midnight to avoid bugs when displaying the day in an input of type date
   */
  static setToUtcMidnight(dateObject) {
    const tz = dateObject.getTimezoneOffset();
    const midnight = new Date(dateObject);
    midnight.setHours(0, 0, 0, 0);
    midnight.setMinutes(-tz);
    return midnight;
  }
}

export class HitActionUtils extends HitUtils {
  /**
   * Different status for an action
   */
  static Status = Object.freeze({
    OPENED: 'OPENED',
    ASSIGNED: 'ASSIGNED',
    CLOSED: 'CLOSED',
  });

  /**
   * Different operations that can be registered in the history
   */
  static Operation = Object.freeze({
    CREATE: 'CREATE',
    ASSIGN: 'ASSIGN',
    UNASSIGN: 'UNASSIGN',
    RENAME: 'RENAME',
    ADD_TAG: 'ADD_TAG',
    REMOVE_TAG: 'REMOVE_TAG',
    MESSAGE: 'MESSAGE',
    START: 'START',
    STOP: 'STOP',
    CLOSE: 'CLOSE',
    REOPEN: 'REOPEN',
  });

  /**
   * Generates a random number with 6 digits
   */
  static generateRandomNo() {
    return Math.floor(100000 + Math.random() * 900000);
  }

  /**
   * The default due date is the same day. If the user wants another due date,
   * he needs to change the date
   */
  static getDefaultDueDate() {
    return new Date().toISOString();
  }

  /**
   * @param id - ID that we want to use for the new action
   * @param data - Data to initialize some columns of the action during the creation
   */
  static create(id, data) {
    return DataService.create('action', {
      ...data,
      id: id,
      no: this.generateRandomNo(),
      designation: data?.designation ?? 'NEW ACTION',
      status: this.Status.OPENED,
      due_date: this.getDefaultDueDate(),
    }).then(() => {
      return DataService.create('action_history', {
        id: HitUUIDUtils.generate(),
        action_id: id,
        operation: this.Operation.CREATE,
        message: data?.designation ?? 'NEW ACTION',
      });
    });
  }

  /**
   * Converts the duration string returned by the database in milliseconds
   * @param d - duration string returned by the database
   * @returns {number}
   */
  static durationToMs(d) {
    if (!d) return 0;
    const pattern = /(?:(\d+) days )?(?:(\d+):(\d+):(\d+)(?:\.(\d+))?)?/;
    const match = d.match(pattern);
    if (!match) {
      throw new Error('Invalid duration format');
    }
    const day = match[1] ? parseInt(match[1], 10) : 0;
    const h = match[2] ? parseInt(match[2], 10) : 0;
    const m = match[3] ? parseInt(match[3], 10) : 0;
    const s = match[4] ? parseInt(match[4], 10) : 0;
    const sMs = match[5] ? parseInt(match[5], 10) : 0;
    const ms = Math.round(sMs / 1000);
    return (day * 24 * 3600 + h * 3600 + m * 60 + s) * 1000 + ms;
  }

  /**
   * Converts the number of milliseconds into a readable time format
   * @param ms - number of milliseconds
   * @returns {string}
   */
  static formatMs(ms) {
    const locale = useConfigurationStore().userLanguageSnakeCase;
    const h = Math.floor(ms / (1000 * 60 * 60))
      .toString()
      .padStart(2, '0');
    const m = Math.floor((ms % (1000 * 60 * 60)) / (1000 * 60))
      .toString()
      .padStart(2, '0');
    const s = Math.round((ms % (1000 * 60)) / 1000)
      .toString()
      .padStart(2, '0');
    switch (locale) {
      case 'en_gb':
        return `${h}:${m}:${s}`;
      default:
        return `${h}h ${m}m ${s}s`;
    }
  }

  /**
   * Converts the duration returned by the database into a readbale time format
   * @param d - duration returned by the database
   * @returns {string}
   */
  static stringifyDuration(d) {
    return this.formatMs(this.durationToMs(d));
  }

  /**
   * Creates the text that will be displayed in the action history
   * @param log - object from the history record
   * @param t - translation function
   * @returns {*}
   */
  static generateHistoryText(log, t) {
    if (log.operation === 'MESSAGE') return log.message;
    return t(
      `hit-app.action.history-${log.operation.toLowerCase().replace('_', '-')}`,
      log
    );
  }
}
