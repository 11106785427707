<template>
  <hit-base-input
    :label="label"
    :full-width="false"
    :validation-state="validationState"
    set-minimum-width
  >
    <div
      ref="input"
      tabindex="0"
      class="flex flex-row items-center h-full border rounded text-input bg-input overflow-hidden focus:ring-transparent focus:outline-none"
      :class="{
        'border-input focus:border-accent':
          (valid && pristine) || !validationState || (valid && dirty),
        'border-danger focus:border-danger': invalid && dirty,
        'cursor-pointer': !disabled && !designation,
        'cursor-default': disabled || designation,
        'xl:w-1/2-max': inTable,
      }"
      @click="!disabled && !designation ? $emit('search') : () => {}"
      @keyup.enter.stop="$emit('search')"
    >
      <div
        class="w-full px-2 xl:w-1/2-max truncate"
        :class="{'cursor-default': !disabled}"
        @click="!disabled ? $emit('search') : () => {}"
      >
        {{ designation }}
      </div>
      <div
        v-if="editable && designation && !disabled"
        class="pr-1 flex items-center cursor-pointer"
        @click="
          (evt) => {
            $emit('edit');
            evt.stopImmediatePropagation();
          }
        "
      >
        <hit-icon icon="edit" />
      </div>
      <div
        v-if="clearable && designation && !disabled"
        class="pr-1 flex items-center cursor-pointer"
        @click="
          (evt) => {
            $emit('clear');
            evt.stopImmediatePropagation();
          }
        "
      >
        <hit-icon icon="clear" />
      </div>
      <div
        v-if="!disabled"
        class="pr-1 flex items-center cursor-pointer flex-grow"
        @click="!disabled ? $emit('search') : () => {}"
      >
        <hit-icon icon="search" />
      </div>
    </div>
  </hit-base-input>
</template>
<script>
import {HitIcon} from '@hit/components';
import HitBaseInput from '@hit/components/src/components/form/input/HitBaseInput.vue';
import HitFormValidationMixin from '@hit/components/src/mixins/form/HitFormValidationMixin';
import HitInputMixin from '@hit/components/src/mixins/form/HitInputMixin';
export default {
  name: 'HitBrowserSelectionDisplay',
  components: {
    HitBaseInput,
    HitIcon,
  },
  mixins: [HitFormValidationMixin],
  props: {
    designation: String,
    label: String,
    clearable: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    inGroupInput: {
      type: Boolean,
      required: false,
      default: false,
    },
    instantFocus: {
      type: Boolean,
      required: false,
      default: false,
    },
    editable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    inTable() {
      return (
        this.$parent.$options.name === 'HitTableCell' ||
        this.$parent.$parent.$options.name === 'HitTableCell' ||
        this.$parent.$parent.$parent.$options.name === 'HitTableCell'
      );
    },
  },
  mounted() {
    if (this.instantFocus) {
      this.$refs.input.focus();
    }
  },
};
</script>
