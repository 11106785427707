<template>
  <div class="inline-grid grid-flow-col gap-1">
    <div
      v-for="tag in tagList"
      :key="tag.designation"
      class="flex-row flex font-medium py-1 px-1 rounded h-5 items-center"
      :style="{
        'background-color': `#${tag.colour}`,
        color: `${ColourUtils.getFontColorOnBg(tag.colour)}`,
      }"
    >
      <div class="px-1 text-xs font-normal leading-none max-w-full">
        {{ tag.designation }}
      </div>
    </div>
  </div>
</template>

<script>
import {ColourUtils} from '../../utils/colour';

export default {
  name: 'TagListReadOnly',
  components: {},
  props: {
    /**
     * We need to pass an array of objects with the following attributes
     * 1) id -> needed to identify a tag if an event is added to the component
     * 2) designation
     * 3) colour
     */
    tagList: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ColourUtils() {
      return ColourUtils;
    },
  },
};
</script>
