export default class ColourUtils {
  /**
   * Calculates the luminance of the bg and returns the font colour to use to have
   * the best user experience
   */
  static getFontColorOnBg(bgColor) {
    bgColor = bgColor.replace('#', '');
    const r = parseInt(bgColor.substring(0, 2), 16);
    const g = parseInt(bgColor.substring(2, 4), 16);
    const b = parseInt(bgColor.substring(4, 6), 16);
    const luminance = (0.2126 * r + 0.7152 * g + 0.0722 * b) / 255;
    return luminance > 0.65 ? 'var(--text-tag-dark)' : 'var(--text-tag-light)';
  }
}
