import {ATTRIBUTE_TYPES} from './HitContainerAttribute';
import {useConfigurationStore} from '../../store';

export const OPERATOR_MAPPING = {
  '>': 'gt',
  '<': 'lt',
  '≥': 'gte',
  '≤': 'lte',
  '=': 'eq',
  '≠': 'neq',
  '∋': 'ilike',
  '∌': 'not.ilike',
};

export function getFilterNames(attribute, key) {
  if (
    [
      ATTRIBUTE_TYPES.JOINED_STRING_COLUMN,
      ATTRIBUTE_TYPES.JOINED_COMPUTED_COLUMN,
    ].includes(attribute.dataType)
  ) {
    return [attribute.column.table + '.and'];
  } else if (attribute.dataType === ATTRIBUTE_TYPES.TAGS_READ_ONLY) {
    return [`${key}.and`];
  } else if (attribute.dataType === ATTRIBUTE_TYPES.ML_WITH_FALLBACK) {
    return ['or'];
  } else {
    return ['and'];
  }
}

export function getColumnNames(attribute, attributeKey) {
  switch (attribute.dataType) {
    case ATTRIBUTE_TYPES.JOINED_STRING_COLUMN:
      return [attribute.column.column];
    case ATTRIBUTE_TYPES.TAGS_READ_ONLY:
      return [attribute.column.column];
    case ATTRIBUTE_TYPES.JOINED_COMPUTED_COLUMN:
      return attribute.column.columns;
    case ATTRIBUTE_TYPES.COMPUTED_COLUMN:
      return attribute.column.columns;
    case ATTRIBUTE_TYPES.ML_WITH_FALLBACK:
      return [
        (attribute.column || attributeKey).replaceAll(
          'LOCALE',
          useConfigurationStore().userLanguageSnakeCase
        ),
        (attribute.column || attributeKey).replaceAll(
          'LOCALE',
          useConfigurationStore().mainLanguageSnakeCase
        ),
      ];
    default:
      return [attribute.column || attributeKey];
  }
}

export function queryForComputedColumns(columns, filters) {
  const subQueries = [];
  Object.entries(filters).forEach(([filter, fValues]) => {
    if (Array.isArray(fValues)) {
      fValues.forEach((entireValue) => {
        let valueQuery = '';
        entireValue
          .split(' ')
          .filter((i) => i !== '')
          .forEach((value) => {
            columns.forEach((column) => {
              valueQuery += `${column}.${OPERATOR_MAPPING[filter]}.*${value}*,`;
            });
          });
        subQueries.push(`or(${valueQuery.slice(0, -1)})`);
      });
    } else {
      fValues
        .split(' ')
        .filter((i) => i !== ' ')
        .forEach((value) => {
          let valueQuery = '';
          columns.forEach((column) => {
            valueQuery += `${column}.${OPERATOR_MAPPING[filter]}.${value},`;
          });
          subQueries.push(`or(${valueQuery.slice(0, -1)})`);
        });
    }
  });
  return subQueries.join(',');
}

export function filtersToString(filtersObject) {
  const stringifyFilters = {};
  Object.entries(filtersObject)
    .filter(([_, value]) => value.length > 0)
    .forEach(([key, values]) => {
      if (values.length === 0) return;
      let queryString = '';
      values.forEach((val) => {
        queryString += `${val},`;
      });
      if (key.includes('or') || key.includes('and')) {
        stringifyFilters[key] = `(${queryString.slice(0, -1)})`;
      } else {
        stringifyFilters[key] = `${queryString.slice(0, -1)}`;
      }
    });
  return stringifyFilters;
}

export function mergeFilters(arrayOfFilterObjects) {
  const mergedFilters = {};
  arrayOfFilterObjects.forEach((filters) => {
    if (!filters) return;
    Object.entries(filters).forEach(([filter, values]) => {
      if (!(filter in mergedFilters)) mergedFilters[filter] = [];
      mergedFilters[filter] = mergedFilters[filter].concat(values);
    });
  });
  return mergedFilters;
}
