<template>
  <hit-group-input
    :label="t('hit-base.common.created-on')"
    input-distribution="max-content max-content max-content 1fr"
  >
    <hit-input
      v-if="creationDate"
      :value="creationDateWrap"
      type="date"
      :disabled="true"
      inline-input
    />

    <hit-input
      v-if="modificationDate"
      :value="modificationDateWrap"
      type="date"
      :label="t('hit-base.common.modified-on')"
      inline-input
      :disabled="true"
      class="modified-on-input"
    />
  </hit-group-input>
</template>

<script>
import {HitBreakpointsMixin, HitGroupInput, HitInput} from '@hit/components';
import {useModelWrapper} from '@/../../hit-base/src/utils/modelWrapper';
import {useI18n} from 'vue-i18n';

export default {
  name: 'HitAppInputTimestamp',
  components: {HitInput, HitGroupInput},
  mixins: [HitBreakpointsMixin],
  props: {
    /**
     * Creation date
     */
    creationDate: Date,
    /**
     * Modification date
     */
    modificationDate: Date,
    initDates: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, {emit}) {
    const {t} = useI18n();
    return {
      creationDateWrap: useModelWrapper(props, emit, 'creationDate'),
      modificationDateWrap: useModelWrapper(props, emit, 'modificationDate'),
      t,
    };
  },
  computed: {
    isDateValid() {
      return (
        this.creationDate &&
        this.creationDate instanceof Date &&
        !isNaN(this.creationDate)
      );
    },
  },
};
</script>

<style lang="scss">
.large-enough-for-form {
  .modified-on-input {
    .hit-form-row-label {
      @apply ml-8;
    }
  }
}
</style>
