<template>
  <form
    :autocomplete="autocomplete ? 'on' : 'off'"
    @submit.prevent
  >
    <div
      :class="{
        'hit-form': !renderAsTable,
        'hit-form-fixed-label-width': fixedLabelWidth,
        'hit-table-form': renderAsTable,
      }"
      @keyup.enter="saveOnReturnKey"
    >
      <slot
        :form-data="state.formData"
        :draft-fields="state.draftFields"
        :validation-state="validationState.formData"
        :cancel-fn="onCancelButtonClick"
        :save-fn="onSaveButtonClick"
        :manual-save="save"
        :can-save="canSave"
        :change-field="onFieldChange"
        :manual-field-change="onManualFieldChange"
      />
      <slot
        name="browser"
        :form-data="state.formData"
        :manual-save="save"
        :cancel-fn="onCancelButtonClick"
      />
      <div
        v-if="!buttonsOnSameLine && (showSaveButton || showCancelButton)"
        class="col-start-2"
      >
        <div
          class="hit-form-row-content form-buttons"
          :class="{
            'one-button': !showCancelButton,
            'two-buttons': showCancelButton,
          }"
        >
          <slot
            name="additionalButtons"
            :form-data="state.formData"
          />
          <div v-if="!forceCancelButtonHidden">
            <hit-button
              v-if="showCancelButton"
              :label="cancelLabel === undefined ? cancelString : cancelLabel"
              class="cancel-button"
              type="reset"
              color="button"
              prefix-icon="clear"
              @click.stop="onCancelButtonClick"
            />
          </div>
          <div v-if="!forceSaveButtonHidden">
            <hit-button
              v-if="showSaveButton"
              id="hit-form-save-button"
              :disabled="!isValid || !enableSaveButton"
              color="accent"
              :prefix-icon="saveIcon"
              :label="saveLabel === undefined ? saveString : saveLabel"
              class="w-full md:w-auto save-button"
              type="submit"
              @click="onSaveButtonClick"
            />
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import _ from 'lodash';
import {computed, reactive} from 'vue';
import {useI18n} from 'vue-i18n';
import useVuelidate from '@vuelidate/core';
import HitButton from '../button/HitButton.vue';

function resetFormData() {
  this.state.formData = _.cloneDeep(this.value);
  if (this.validationState && (!this.invalidSaveAllowed || this.isValid)) {
    this.validationState.$reset();
  }
}

export default {
  name: 'HitForm',
  components: {HitButton},
  provide() {
    return {
      hitFormFieldInput: this.onFieldInput,
      hitFormFieldChanged: this.onFieldChange,
    };
  },
  inject: {
    hitFormFieldChanged: {
      default: undefined, // a default value is required to suppress warning if not found
    },
    hitFormFieldInput: {
      default: undefined, // a default value is required to suppress warning if not found
    },
  },
  props: {
    /**
     * Value of the form
     */
    value: {
      /* Initial value of formData */
      type: Object,
      default() {
        return {};
      },
    },
    /**
     * Validations of the form
     */
    validations: {
      type: Object,
      default() {
        return {};
      },
    },
    /**
     * Enable autosave
     */
    autosave: Boolean,
    /**
     * Enable autocomplete
     */
    autocomplete: Boolean,
    /**
     * Enable cancel button of the form
     */
    cancellable: Boolean,
    /**
     * Label of the save button
     */
    saveLabel: {
      type: String,
    },
    /**
     * Label of the cancel button
     */
    cancelLabel: {
      type: String,
    },
    /**
     * Icon of the save button
     */
    saveIcon: {
      type: String,
      default: 'save',
    },
    /**
     * Enable save when form is invalid
     */
    invalidSaveAllowed: Boolean,
    /**
     * Render form as table
     */
    renderAsTable: Boolean,
    /**
     * Enable touch of the validationState
     */
    touchValidationState: Boolean,
    /**
     * Enable fixed label width
     */
    fixedLabelWidth: {
      type: Boolean,
      default: true,
    },
    /**
     * Function to be executed before saving to add additional fields or override existing
     */
    beforeSaveFn: {
      type: Function,
      default() {
        return null;
      },
    },
    name: {
      type: String,
      required: false,
      default: null,
    },
    resetValidationIfValid: {
      type: Boolean,
      default: false,
    },
    /** Used for special situations where we want to add more possibilities to check the form **/
    forceSaveButtonHidden: {
      type: Boolean,
      default: false,
    },
    /** Used for special situations where we want to add more possibilities to check the form **/
    forceCancelButtonHidden: {
      type: Boolean,
      default: false,
    },
    buttonsOnSameLine: {
      type: Boolean,
      default: false,
    },
    saveOnEnterKeyAllowed: {
      type: Boolean,
      required: false,
      default: true,
    },
    useCustomSave: {
      type: Boolean,
      required: false,
      default: false,
    },
    enableSaveButton: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup(props) {
    //translations
    const {t} = useI18n(); // use as global scope
    const cancelString = computed(() => t('hit-components.common.cancel'));
    const saveString = computed(() => t('hit-components.common.save'));

    //vuelidate
    const validations = computed(() => {
      let validationCopy = _.cloneDeep(props.validations);
      // Add formData in front of input keys in $validationGroups
      if (validationCopy.$validationGroups) {
        for (let key of Object.keys(validationCopy.$validationGroups)) {
          for (
            let i = 0;
            i < validationCopy.$validationGroups[key].length;
            i++
          ) {
            validationCopy.$validationGroups[key][
              i
            ] = `formData.${validationCopy.$validationGroups[key][i]}`;
          }
        }
      }
      // validations for creation form
      let result = {
        formData: validationCopy,
      };
      if (validationCopy.$validationGroups) {
        result.$validationGroups = validationCopy.$validationGroups;
        delete validationCopy.$validationGroups;
      }
      return result;
    });
    const state = reactive({
      formData: undefined, // current form data (draft state)
      draftFields: new Set(), // dirty by unsaved fields
    });
    const v$ = useVuelidate(validations, state);

    return {t, cancelString, saveString, v$, state};
  },
  computed: {
    validationState() {
      return this.v$;
    },
    showSaveButton() {
      return !this.autosave;
    },
    showCancelButton() {
      return this.cancellable;
    },
    isValid() {
      return this.validationState && !this.validationState.$invalid;
    },
    canSave() {
      return this.isValid || !!this.invalidSaveAllowed;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        resetFormData.bind(this).call();
      },
      deep: true,
    },
  },
  methods: {
    saveOnReturnKey($event) {
      if (this.canSave && this.saveOnEnterKeyAllowed) {
        this.onSaveButtonClick($event);
      }
    },
    onFieldChange(field, value) {
      this.state.formData[field] = value;
      this.state.draftFields.add(field);
      if (this.canSave && this.autosave) {
        this.save();
      }
    },
    onFieldInput(field, value) {
      this.state.formData[field] = value;
      this.state.draftFields.add(field);
    },
    onManualFieldChange(dataObject) {
      for (let field in dataObject) {
        this.state.formData[field] = dataObject[field];
        this.state.draftFields.add(field);
      }
      if (this.canSave && this.autosave) {
        this.save();
      }
    },
    onSaveButtonClick: function ($event) {
      if (!this.forceSaveButtonHidden) {
        if (!this.useCustomSave) {
          this.save(true);
        } else {
          this.customSave();
        }
      }
      if ($event) {
        $event.stopImmediatePropagation();
      }
    },
    onCancelButtonClick() {
      if (!this.forceCancelButtonHidden) {
        resetFormData.bind(this).call();
        /**
         * When the form modifications are cancelled
         */
        this.$emit('cancel', this.formData);
      }
    },
    async save(forceSaveAll = false) {
      // only send draft fields to allow partial update
      let dataToSave = {};
      let dataToSaveAll = {...this.state.formData};
      for (let draftField of this.state.draftFields) {
        dataToSave[draftField] = this.state.formData[draftField];
      }

      if (this.beforeSaveFn) {
        let additionalFormData = await this.beforeSaveFn(this.state.formData);

        if (additionalFormData) {
          const additionalFormDataKeys = Object.keys(additionalFormData);

          for (let i = 0; i < additionalFormDataKeys.length; i++) {
            dataToSave[additionalFormDataKeys[i]] =
              additionalFormData[additionalFormDataKeys[i]];
            dataToSaveAll[additionalFormDataKeys[i]] =
              additionalFormData[additionalFormDataKeys[i]];
          }
        }
      }
      this.$emit('change', dataToSave[this.name]);
      if (this.name) {
        if (this.hitFormFieldChanged) {
          this.hitFormFieldChanged(this.name, dataToSave[this.name]);
        } else {
          // TODO: no parent form to change the value
        }
      }

      if (this.resetValidationIfValid && this.isValid) {
        this.validationState.$reset();
      }
      /**
       * When the form modifications are saved
       */
      if (forceSaveAll || !this.autosave) {
        this.$emit('saveAll', dataToSaveAll);
      } else {
        this.$emit('autoSave', dataToSaveAll);
      }
      this.$emit('save', dataToSave);
      this.state.draftFields = new Set();
    },
    async customSave() {
      this.$emit('save', {...this.state.formData});
    },
  },
};
</script>

<style lang="scss">
.hit-form {
  //@apply grid gap-1 grid-cols-1 sm:grid-flow-row sm:auto-rows-auto;
}

.large-enough-for-form {
  .hit-form {
    @apply grid gap-1 grid-cols-1 sm:grid-flow-row sm:auto-rows-auto;
    grid-template-columns: auto minmax(50%, 1fr);

    &.hit-form-fixed-label-width {
      grid-template-columns: minmax(7rem, 1fr) 5fr;
    }
  }
}

@screen sm {
  .hit-table-row {
    @apply grid-cols-1;
  }
}

@screen md {
  .form-buttons {
    @apply flex flex-row justify-end gap-2 pr-1;
  }
}
</style>
