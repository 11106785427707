<template>
  <hit-select
    :label-fields="['displayLabel']"
    :options="optionsLocale"
    :options-to-exclude="optionsToExclude"
    :selection-type="selectionType"
    :value="container.data[attribute]"
    :multiple="multiple"
    :full-width="fullWidth"
    :disabled="container.attributes[attribute].readOnly"
    @add="handleAdd"
    @remove="handleRemove"
  />
</template>

<script>
import {HitSelect} from '@hit/components';
import {DataService} from '@hit/base/src';
import {useI18n} from 'vue-i18n';

export default {
  name: 'HitContainerEnumLabelSelect',
  components: {HitSelect},
  props: {
    container: {
      type: Object,
      required: true,
    },
    attribute: {
      type: String,
      required: true,
    },
    route: {
      type: String,
      required: false,
    },
    enumType: {
      type: String,
      required: true,
      default: '',
    },
    fullWidth: {
      type: Boolean,
      required: false,
      default: false,
    },
    excludeKey: {
      type: String,
      required: false,
      default: null,
    },
    optionsToExcludeMapping: {
      type: Object,
      required: false,
      default: null,
    },
  },
  setup() {
    const {t} = useI18n();
    return {t};
  },

  data() {
    return {
      options: [],
      selectionType: 'ENUM',
    };
  },
  computed: {
    optionsLocale() {
      return this.options.map((option) => {
        let enumLabel = `hit-app.${this.enumType.replace(
          /_/g,
          '-'
        )}.${option.enum_label.toLowerCase().replace(/_/g, '-')}`;
        const displayLabel = this.t(enumLabel);
        return {
          ...option,
          displayLabel: displayLabel,
        };
      });
    },
    optionsToExclude() {
      if (
        this.excludeKey &&
        this.optionsToExcludeMapping &&
        this.excludeKey in this.optionsToExcludeMapping
      ) {
        const invalidOptions = this.optionsToExcludeMapping[this.excludeKey];
        return invalidOptions ? invalidOptions : [];
      }
      return [];
    },
  },
  created() {
    this.loadOptions();
  },
  methods: {
    loadOptions() {
      DataService.procedure('get_enum_labels', {enum_name: this.enumType}).then(
        (response) => {
          this.options = response.data.map((obj) => ({
            ...obj,
            id: obj.enum_label,
          }));
        }
      );
    },
    handleAdd(value) {
      let enum_object = this.options[value];
      let enum_value_to_save = enum_object.enum_label;

      if (this.container.multiple) {
        this.$emit('change-label', enum_value_to_save);
      } else {
        this.container.handleChange(this.attribute, enum_value_to_save);
        this.$emit('set-label', enum_value_to_save);
      }
    },
    handleRemove(value) {
      this.$emit('remove', value);
    },
  },
};
</script>
